<template>
  <div class="error-404">
      <h2 class="">Error Page</h2>
      <hr>
      <p>Ops, there has been an error or the page is not found. If the error persists, please contact support.</p>
      <p>Please try going to the home page and starting again.</p>
        <ButtonCustom
            type="primary"
            text="try again"
            @onClickButton="onClickBackToHome"
        />
      <hr>
  </div>
</template>
<script>
import ButtonCustom from "@/components/common/button";

export default {
      components: {
          ButtonCustom,
      },
      methods: {
        onClickBackToHome() {
            this.$router.push("/home");
        },
    },
}
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/_error404.scss"
/>
